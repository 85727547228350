<template>

<app-page>

	<app-page-head title="Settings">

		<app-page-head-action v-if="!is.deleting" :disabled="!isDirty" icon="undo" tooltip="Undo changes" v-on:click.native="onUndoClick" />
		<app-page-head-action v-if="!is.deleting" :loading="is.saving" :disabled="!isDirty" icon="save" tooltip="Save changes" v-on:click.native="onSaveClick" />
		<app-page-head-action v-if="!is.deleting" icon="delete" :danger="true" tooltip="Delete guide" v-on:click.native="onDeleteClick" />
		
	</app-page-head>

	<app-page-content :is-grown="true">

		<app-page-content-section :is-padded="true" v-if="!is.deleting">

			<app-input-text v-model="model.name" :maxlength="32" :validation="$v.model.name" label="Name" placeholder="Enter name" />
			<app-input-text v-model="model.initials" :maxlength="2" :validation="$v.model.initials" label="Initials" placeholder="Enter initials" />
			<app-input-colour v-model="model.color.fore" :validation="$v.model.color.fore" label="Foreground" placeholder="Enter color" />
			<app-input-colour v-model="model.color.back" :validation="$v.model.color.back" label="Background" placeholder="Enter color" />
			<app-input-file v-model="model.pdf.en" :validation="$v.model.pdf.en" label="PDF (EN)" />
			<app-input-file v-model="model.pdf.es" :validation="$v.model.pdf.es" label="PDF (ES)" />

		</app-page-content-section>

		<app-page-content-section :is-padded="true" v-if="is.deleting" class="delete" :class="{'is-loading': is.confirming}">

			<app-icon icon="warning" class="delete-icon" />

			<div class="delete-title">Delete guide</div>

			<div class="delete-text">Are you sure you wish to delete the guide? This cannot be reversed.</div>

			<div class="delete-buttons">

				<div class="delete-buttons-item is-danger" v-on:click="onConfirmClick">
					<template v-if="!is.confirming">Confirm</template>
					<app-icon icon="loading" v-if="is.confirming" />
				</div>

				<div class="delete-buttons-item" v-on:click="onCancelClick">Cancel</div>

			</div>

		</app-page-content-section>

	</app-page-content>

	<app-page-foot />

</app-page>

</template>

<script>

import mixForm from '@/mixin/form'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			isStandalone: true,
			store: 'guide',
			is: {
				confirming: false,
				deleting: false,
			},
			model: {
				initials: '',
				name: '',
				color: {
					fore: '',
					back: ''
				},
				pdf: {
					en: '',
					es: ''
				}
			}
		}

	},

	validations: {
		model: {
			initials: {
				required,
				maxLength: maxLength(2)
			},
			name: {
				required,
				maxLength: maxLength(32)
			},
			color: {
				fore: {
					required,
					minLength: minLength(6),
					maxLength: maxLength(6)
				},
				back: {
					required,
					minLength: minLength(6),
					maxLength: maxLength(6)
				}
			},
			pdf: {}
		}
	},

	methods: {

		default: function() {

			this.model.initials = this.$store.getters['guide'].initials
			this.model.name = this.$store.getters['guide'].name
			this.model.color.fore = this.$store.getters['guide'].color.fore
			this.model.color.back = this.$store.getters['guide'].color.back
			this.model.pdf.en = this.$store.getters['guide'].pdf.en
			this.model.pdf.es = this.$store.getters['guide'].pdf.es

		},

		goBackAfterSave: function() {

			this.is.saving = false

		},

		onConfirmClick: async function() {

			if(!this.is.confirming) {

				this.is.confirming = true

				await this.$store.dispatch('guide/delete')

				this.$router.push({
					name: 'Dashboard'
				})
			
			}
		
		},

		onCancelClick: function() {

			this.is.deleting = false
		
		},

		onDeleteClick: function() {
		
			this.is.deleting = true
		
		}

	} 

}

</script>

<style scoped>

.delete {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #f25151;
}

.delete.is-loading {
	pointer-events: none;
}

.delete-icon {
	font-size: 96px;
}

.delete-title {
	font-size: 24px;
	font-weight: 400;
	padding: 20px 0px;
}

.delete-text {
	font-size: 16px;
	line-height: 20px;
	padding: 0px 100px;
	text-align: center;
	color: #333;
	font-weight: 300;
}

.delete-buttons {
	margin-top: 20px;
	display: flex;
	justify-content: center;
}

.delete-buttons-item {
	width: 150px;
	height: 40px;
	border: 2px solid #ccc;
	line-height: 36px;
	text-align: center;
	border-radius: 16px;
	color: #333;
	margin: 0px 10px;
	font-size: 16px;
	cursor: pointer;
}

.delete-buttons-item.is-danger {
	color: #fff;
	font-weight: 400;
	border-color: #f25151;
	background-color: #f25151;
}

</style>
